@import '../../../../styles/all';

.parentWrapper {
  display: flex;
  column-gap: 12px;
  @media (max-width: $screen-lg) {
    flex-direction: column;
  }
  .mediumSize {
    width: 600px;
    @media (max-width: $screen-lg) {
      width: 100%;
    }
  }
}

.container {
  width: 820px;
  height: 548px;
  padding: 60px 40px;
  margin-bottom: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(126, 126, 126, 0.1);
  border-radius: 8px;

  @media (max-width: $screen-md) {
    width: 100%;
    margin-top: 20px;
    padding: 40px 30px;
  }

  @media (max-width: $screen-sm) {
    height: auto;
    padding: 20px 16px;
  }

  h3 {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .description {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .inputWrapper {
    margin-top: 30px;
    .label {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      text-transform: uppercase;
    }
    .input {
      margin-top: 6px;
      width: 100%;
      height: 40px;
    }
    .dropzone {
      display: flex;
      width: 100%;
      height: 190px;
      background: #FFFFFF;
      border: 2px dashed #E3E3E3;
      border-radius: 16px;
      justify-content: center;
      align-items: center;
      .items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        .selectButton {
          margin-top: 10px;
          width: 180px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 500;
          color: #2666E6;
          background: #E9F0FC;
          border-radius: 8px;
          cursor: pointer;
        }
        .selectButton:hover {
          background: #a9c0fc;
        }
      }
      .dropzoneTitle {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #0F172B;
      }
      .dropzoneDescription {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #84899F;
      }
    }
    .fileItems {
      margin-top: 16px;
      height: 208px;
      overflow: auto;
    }
    .amendItems {
      margin-top: 16px;
      height: 370px;
      overflow: auto;
    }
    .errorLabel {
      color: #FF0000;
      font-size: 12px;
    }    
  }
}