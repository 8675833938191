.primary-button {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 184px;
  min-width: 368px;
  position: absolute;
  bottom: 84px;
}

.overlap-group-4 {
  height: 40px;
  position: relative;
  width: 368px;
}

.primary-button-3 {
  align-items: center;
  background-image: url(/static/img/rectangle-88@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 40px;
  left: 26px;
  min-width: 352px;
  padding: 0 132.9px;
  position: absolute;
  top: 872px;
  transition: all 0.2s ease-in;
}

.rectangle-88 {
  cursor: pointer;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 368px;
}

.add-a-new-property {
  left: 90px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.primary-button.primary-button-4 {
  align-self: flex-end;
  cursor: pointer;
  height: unset;
  left: unset;
  margin-right: 8px;
  margin-top: 24px;
  min-width: 352px;
  position: unset;
  top: unset;
  transition: all 0.2s ease-in;
}

.primary-button.primary-button-4:hover {
  transform: scale(1.1);
}

.primary-button.primary-button-4 .overlap-group-4,
.primary-button.primary-button-4 .rectangle-88 {
  width: 352px;
}

.primary-button.primary-button-4 .add-a-new-property {
  left: 109px;
}

