.createtenant {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1024px;
  overflow: hidden;
  padding: 0 8px;
  width: 1424px;
}

.overlap-group3-2 {
  height: 1008px;
  margin-top: 24px;
  position: relative;
  width: 1408px;
}

.rectangle-159-1 {
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 8px 40px #00000014;
  height: 976px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 1328px;
}

.rectangle-165 {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 40px #00000005;
  height: 578px;
  left: 712px;
  position: absolute;
  top: 336px;
  width: 560px;
}

.rectangle-150-2 {
  height: 136px;
  left: 0;
  position: absolute;
  top: 872px;
  width: 1408px;
}

.rectangle-162-1 {
  height: 56px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 1328px;
}

.group-349-1 {
  align-items: center;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  height: 24px;
  left: 1248px;
  min-width: 24px;
  padding: 0 6px;
  position: absolute;
  top: 16px;
}

.vector-2 {
  height: 12px;
  width: 12px;
}

.creating-a-new-tenant {
  left: 136px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.create-property-steps {
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  white-space: nowrap;
}

.create-property-steps.name {
  top: 93px;
  left: 132px;
}

.create-property-steps.sqfeet {
  top: 390px;
  left: 132px;
}

.create-property-steps.upload {
  top: 520px;
  left: 132px;
}

.create-property-steps.organize {
  top: 93px;
  left: 710px;
}

.step-1-name-tenant {
  left: 132px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 93px;
  white-space: nowrap;
}

.step-3-organize-the-files-as-needed {
  left: 712px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 88px;
  white-space: nowrap;
}

.rectangle-163 {
  background-color: var(--white);
  border: 2px solid var(--ebb);
  border-radius: 16px;
  height: 120px;
  left: 136px;
  position: absolute;
  top: 423px;
  width: 464px;
}

.rectangle-164 {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 40px #00000005;
  height: 124px;
  left: 712px;
  position: absolute;
  top: 190px;
  width: 560px;
}

.rectangle-166 {
  background-color: var(--baby-powder);
  border-radius: 8px;
  box-shadow: 0px 0px 40px #00000005;
  height: 1px;
  left: 136px;
  position: absolute;
  top: 830px;
  width: 464px;
}

.group-487 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 259px;
  min-height: 48px;
  position: absolute;
  top: 459px;
  width: 214px;
}

.select-the-pdf-files-to-upload {
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 4.0px;
  min-height: 24px;
  min-width: 210px;
  text-align: center;
  white-space: nowrap;
}

.or-drag-and-drop-them-here {
  color: #84899f;
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 5.0px;
  margin-top: 8px;
  min-height: 16px;
  min-width: 175px;
  white-space: nowrap;
}

.group-491 {
  align-items: flex-end;
  display: flex;
  left: 132px;
  min-width: 474px;
  position: absolute;
  top: 128px;
}
.group-492 {
  align-items: flex-end;
  display: flex;
  left: 132px;
  min-width: 474px;
  position: absolute;
  top: 320px;
}

.step2-group {
  align-items: flex-end;
  display: flex;
  left: 132px;
  min-width: 474px;
  position: absolute;
  top: 430px;
}

.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 372px;
}

.overlap-group-11 {
  align-items: flex-start;
  background-color: var(--black-haze);
  border-radius: 4px;
  display: flex;
  height: 40px;
  min-width: 464px;
  padding: 7px 9px;
}

.overlap-group-12 {
  align-items: flex-start;
  background-color: var(--black-haze);
  border-radius: 4px;
  display: flex;
  height: 40px;
  min-width: 464px;
  padding: 7px 9px;
}

.dunkin-donuts {
  background-color: transparent;
  border: 0;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  white-space: nowrap;
  width: 100%;
}

.dunkin-donuts::placeholder {
  color: #aaadbd99;
}

.or-upload-them-from-a-url {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 0px;
  margin-top: 40px;
  min-height: 24px;
  white-space: nowrap;
}

.overlap-group1-3 {
  align-items: flex-start;
  background-color: var(--black-haze);
  border-radius: 4px;
  display: flex;
  height: 40px;
  margin-left: 4px;
  margin-top: 16px;
  min-width: 368px;
  padding: 7px 15px;
}

.add-the-url {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.lease {
  left: 740px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 204px;
  white-space: nowrap;
}
.leaseCard{
  left: 740px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  border-color: #cbd5e1;
  top: 230px;
  width: 464px;
  height: 60px;
}

.amendments {
  left: 740px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 352px;
  white-space: nowrap;
}
.amendmentCards{
  left: 740px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  border-color: #cbd5e1;
  top: 380px;
  width: 464px;
  height: 500px;
}

.drag-and-drop-your-f {
  left: 712px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 119px;
  width: 464px;
}

#form-file-upload {
  height: 16rem;
  width: 464px;
  max-width: 100%;
  text-align: center;
  position: relative;
  top: 520px;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}