#root,
body,
html {
  font-family: sans-serif;
  max-height: 500px;
}

#root {
  max-height: 500px;
}
