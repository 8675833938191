.top-nav-2 {
  align-items: flex-start;
  display: flex;
  height: 56px;
  left: 0;
  min-width: 1056px;
  position: absolute;
  top: 0;
}

.overlap-group1-1 {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  height: 56px;
  min-width: 1424px;
  padding: 0 27px;
}

.whitemarsh-pro-2 {
  cursor: pointer;
  letter-spacing: 0;
  min-height: 18px;
  min-width: 123px;
  text-align: center;
}

