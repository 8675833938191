@import '../../styles/all';

.popupContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  .container {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: #2666E6;
    justify-content: space-between;
    align-items: center;
    padding: 0 42px 0 69px;
    h3 {
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 20px;
      color: white;
    }
    p{
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      color: white;
    }
    .reviewButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 41px;
      background-color: white;
      color: #2666E6;
      box-shadow: 0px 4px 16px rgba(126, 126, 126, 0.25);
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #dedede,
      }
    }
    
  }
}