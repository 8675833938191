.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  .button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}

.input {
  font-family: "Roboto";
  font-size: 16px;
  color: "black";
  padding: 12px;
  background-color: transparent;
  border: 1px solid #DFE2E8;
  border-radius: 8px;
  &:focus {
    outline: none;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C4C4C4;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error {
    border: 1px solid #FF0000;
  }
}

.errorLabel {
  color: #FF0000;
  font-size: 12px;
}
