.back-button {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: flex-end;
  left: 194px;
  min-width: 135px;
  position: absolute;
  top: 20px;
}

.arrow-left-icon {
  height: 14px;
  width: 16px;
}

.back-to-all-tenants {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px;
  min-height: 16px;
  min-width: 111px;
  text-align: center;
  white-space: nowrap;
}

.back-button.back-button-1 {
  cursor: pointer;
}

.back-button.back-button-1 .back-to-all-tenants {
  min-width: 125px;
  text-align: unset;
}

