.tabs-open {
  align-items: center;
  background-image: url(/static/img/rectangle-80@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 40px;
  margin-bottom: 0.05px;
  min-width: 96px;
  padding: 0 21px;
}

.surname {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 54px;
  text-align: center;
  white-space: nowrap;
}

