.tabs-unselected {
  align-items: flex-end;
  align-self: flex-start;
  background-image: url(/static/img/rectangle-81@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 43px;
  margin-top: -0.48px;
  min-width: 90px;
  padding: 13.0px 29px;
}

.notes {
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 32px;
  text-align: center;
  white-space: nowrap;
}

.tabs-unselected.tabs-unselected-1 {
  align-items: center;
  align-self: unset;
  background-image: unset;
  height: 40px;
  justify-content: flex-end;
  margin-bottom: 0.05px;
  margin-top: unset;
  min-width: 92px;
  padding: 0 20px;
}

.tabs-unselected.tabs-unselected-1 .notes {
  min-width: 51px;
}

.tabs-unselected.tabs-unselected-2 {
  align-items: center;
  align-self: unset;
  background-image: unset;
  height: 40px;
  justify-content: flex-end;
  margin-bottom: 0.05px;
  margin-top: unset;
  padding: 0 29.0px;
}

