.primary-button-5 {
  align-items: center;
  background-image: url(/static/img/rectangle-88-2@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 40px;
  margin-left: 16px;
  min-width: 80px;
  padding: 0 14.8px;
}

.add-a-new-property-2 {
  color: #dedfe4;
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-weight: 800;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 50px;
  text-align: center;
  white-space: nowrap;
}

