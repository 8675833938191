.searchBar {
  margin-top: 16px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.highlightTooltip {
  width: 140px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2666E6;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  text-transform: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}

.highlightTooltip::after {
  position: absolute;
  top: -9px;
  left: 60px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #2666E6;
  content: " ";
  width: 0;
}

.distractor {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 16px;
    text-align: center;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loadingSpinner {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #2666E6; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}
