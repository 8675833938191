@import '../../styles/all';

.popupContainer {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 956px;
    padding: 60px 100px;
    background-color: white;
    border-radius: 8px;
    
    @media (max-width: $screen-md) {
      padding: 40px 60px;
    }

    @media (max-width: $screen-sm) {
      height: 100%;
      padding: 20px 30px;
      overflow-y: scroll;
    }

    .inputWrapper {
      margin-top: 30px;
      .label {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        text-transform: uppercase;
      }
      .input {
        margin-top: 6px;
        width: 100%;
        height: 40px;
      }
      .select {
        margin-top: 6px;
        height: 40px;
        padding: 0 12px;
        border: 1px solid #DFE2E8;
        border-radius: 8px;
        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
      .errorLabel {
        color: #FF0000;
        font-size: 12px;
      }
    }
    .cityWrapper {
      width: 320px;
      @media (max-width: $screen-md) {
        width: 100%;
      }
    }
  }
}