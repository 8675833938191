.group-450 {
  align-items: flex-start;
  background-color: var(--black-haze);
  border-radius: 4px;
  display: flex;
  height: 40px;
  left: 1064px;
  min-width: 352px;
  padding: 11px 13.6px;
  position: absolute;
  top: 8px;
}

.icon-search {
  align-self: center;
  height: 14px;
  width: 14px;
}

.search {
  background-color: transparent;
  border: 0;
  height: 20px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 9px;
  padding: 0;
  white-space: nowrap;
  width: 100%;
  
}

.search::placeholder {
  color: #5279b899;
}

.group-450.group-450-1 {
  left: unset;
  margin-left: 8px;
  position: unset;
  top: unset;
}

