@import '../../styles/all';

.searchContainer {
  display: flex;
  width: 335px;
  height: 45px;
  padding: 12px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DFE2E8;
  border-radius: 8px;
  
  @media (max-width: $screen-md) {
    width: 100%;
  }
}