@import '../../styles/all';

.container {
  position: relative;
  margin-top: 8px;
  display: flex;
  padding: 0 16px 0 20px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1.5px solid #CECECE;
  box-shadow: 0px 4px 20px rgba(126, 126, 126, 0.15);
  border-radius: 8px;
  @media (max-width: $screen-sm) {
    height: 80px;
  }
  .fileInfo {
    z-index: 1;
    display: flex;
    align-items: center;
    column-gap: 8px;
    .fileName {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
    .fileSize {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      opacity: 0.5;
    }
  }
  .progressBar {
    z-index: 0;
    border-radius: 8px;
    background: rgba(38, 102, 230, 0.1);
    position: absolute;
    left: 0;
    top: 0;
    bottom:0;
  }
}
