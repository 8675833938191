:root { 
  --azure-radiance: #017efa;
  --baby-powder: #fcfcfc;
  --black: #000000;
  --black-haze: #f5f7f9;
  --ebb: #e3e3e3;
  --fuscous-gray: #555555;
  --gallery: #edeef0;
  --geyser: #d2dbeb;
  --gray: #7e7e7e;
  --mirage: #0f172b;
  --mystic: #e0e6f1;
  --red: #ff0000;
  --spun-pearl: #aaadbd;
  --steel-blue: #5279b8;
  --tory-blue: #1a4ea2;
  --tory-blue-2: #1a4da2;
  --white: #ffffff;
 
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 20px;
  --font-size-xs: 13px;
  --font-size-xxs: 12px;
  --font-size-xxxs: 10px;
 
  --font-family-avenir_next-bold: "Avenir Next-Bold", Helvetica;
  --font-family-lato: "Lato", Helvetica;
}
.lato-bold-tory-blue-10px {
  color: var(--tory-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 700;
}

.lato-normal-azure-radiance-12px {
  color: var(--azure-radiance);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-mirage-16px {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.lato-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-tory-blue-10px {
  color: var(--tory-blue-2);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-tory-blue-10px-2 {
  color: var(--tory-blue-2);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 700;
}

.lato-normal-tory-blue-16px {
  color: var(--tory-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.lato-extra-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 800;
}

.lato-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.lato-extra-bold-steel-blue-12px {
  color: var(--steel-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 800;
}

.lato-normal-mirage-12px {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.avenirnext-bold-azure-radiance-13px {
  color: var(--azure-radiance);
  font-family: var(--font-family-avenir_next-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.lato-extra-bold-steel-blue-10px {
  color: var(--steel-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 800;
}

.lato-normal-gray-10px {
  color: var(--gray);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-fuscous-gray-12px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-mirage-18px {
  color: var(--mirage);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-tory-blue-20px {
  color: var(--tory-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.lato-extra-bold-tory-blue-10px {
  color: var(--tory-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 800;
}

.lato-normal-spun-pearl-14px {
  color: var(--spun-pearl);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-spun-pearl-16px {
  color: var(--spun-pearl);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.border-4px-red {
  border: 4px solid var(--red);
}

.border-1px-mystic {
  border: 1px solid var(--mystic);
}

.border-1px-geyser {
  border: 1px solid var(--geyser);
}

.border-1px-gallery {
  border: 1px solid var(--gallery);
}

.border-1px-ebb {
  border: 1px solid var(--ebb);
}
