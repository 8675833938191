@import '../../styles/all';

.tabOptionsContainer {
  display: flex;
  // height: 100%;

  // > :not(:first-child) {
  //   margin-left: 20px;
  // }

  @media (max-width: $screen-md) {
    //display: none;
  }
}
.tab {
  // border-bottom: 1px solid rgba(239, 239, 241, 0.4);
  color: black;

  > :first-child {
    text-transform: capitalize;
    cursor: pointer;
    padding: 8px 22px;
    @media (max-width: $screen-md) {
      padding: 8px 10px;
    }
  }
}

.tabBackgroundActive {
  > :first-child {
    background: linear-gradient(
      180deg,
      #e82e89 0%,
      rgba(133, 28, 79, 0.79) 100%
    );
    border-radius: 4px 4px 0px 0px;

    @media (max-width: $screen-md) {
      padding: 8px 20px;
    }
  }
}

.tabActive {
  > :first-child {
    border-bottom: 2px solid #f1ff54;
  }
}
