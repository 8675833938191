.searchContainer {
  position: relative;
  display: flex;
  width: 335px;
  height: 45px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DFE2E8;
  border-radius: 8px;
  .searchInputContainer {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .resultContainer {
    display: flex;
    align-items: center;

    .resultText {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}