.container {
  position: relative;
  width: 100%;
  padding: 36px;
  max-width: 428px;
  max-height: calc(100vh - 80px);
  background: #2E3755;
  box-shadow: 0px -10px 40px rgba(155, 155, 155, 0.25);
  border-radius: 8px 0px 0px 8px;
  .expand {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #2E3755;
    border-radius: 4px;
    top: 30px;
    left: -20px;
  }

  &.expandMode {
    max-width: 628px;
    animation-name: expandAnimation;
    animation-duration: 0.3s;
  }

  @keyframes expandAnimation {
    from {
      max-width: 428px;
    }
    to {
      max-width: 628px;
    }
  }
  textarea {
    resize: vertical;
  }
}

.searchBar {
  margin-top: 16px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.toggleContainer {
  margin-top: 16px;
  display: flex;
  height: 45px;
  color: white;
  .leftSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: #2666E6;
    border: 1.5px solid #2666E6;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
  }
  .leftDeselected {
    background: transparent;
  }
  .rightSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: #2666E6;
    border: 1.5px solid #2666E6;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
  }
  .rightDeselected {
    background: transparent;
  }
}

.textarea {
  padding: 4px;
  height: 100px;
  font-size: 12px;
  color: white;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.activeTextArea {
  background-color: rgba(255, 255, 255, 0.5) ;
}

.pdfContainer {
  padding: 20px;
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
  }
  th, td {
    font-size: 14px;
  }
}