@import '../../../../styles/all';

.container {
  width: 368px;
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: $screen-md) {
    top: 80px;
  }

  .shapeContainer {
    display: flex;
    margin-left: 24px;
    align-items: center;

    .filledCircle {
      width: 25px;
      height: 25px;
      background: #2666E6;
      border: 2px solid #2666E6;
      border-radius: 25px;
    }
    .outlinedCircle {
      width: 25px;
      height: 25px;
      border: 2px solid #2666E6;
      border-radius: 25px;
    }
    .emptyCircle {
      width: 25px;
      height: 25px;
      border: 2px solid #DFE2E8;
      border-radius: 25px;
    }
    .outlinedLine {
      width: 115px;
      height: 0px;
      border: 1px solid #2666E6;
    }
    .emptyLine {
      width: 115px;
      height: 0px;
      border: 1px solid #DFE2E8;
    }
  }

  .labelContainer {
    display: flex;
    justify-content: space-between;
    p {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      color: #232839;
      @media (max-width: $screen-sm) {
        font-size: 14px;
      }
    }
  }  
}