.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 214px;
  justify-content: space-between;
  background-color: "white";
  border-radius: 8px;
  box-shadow: 0px 4px 25px rgba(126, 126, 126, 0.1);
  padding: 22px 22px 16px 22px;
  color: black;
  cursor: pointer;

  &.disabledState {
    color: #bcbcbc;
  }

  .header {
    .retail {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    h3 {
      margin-top: 6px;
      min-height: 56px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
      -webkit-box-orient: vertical;
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
    }
  }
  .footer {
    .street {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
    }
    .lastModifiedDate {
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
      color: black;
      opacity: 0.38;
    }
  }
}


