@import '../../styles/all';

.container {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 16px 0 20px;
  height: 80px;
  background: #FFFFFF;
  border: 1.5px solid #CECECE;
  box-shadow: 0px 4px 20px rgba(126, 126, 126, 0.15);
  border-radius: 8px;
  @media (max-width: $screen-sm) {
    height: 120px;
    padding: 0 10px 0 16px;
  }
  .normalContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between; 

    .firstContainer {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .fileInfo {
      display: flex;
      flex-direction: column;
      column-gap: 8px;
      .amendmentName {
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      .fileName {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      .fileSize {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        opacity: 0.5;
      }
    }
  }
  .editContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    .topContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .amendmentName {
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .bottomContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      .input {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.blueBackground {
  height: 100px;
  background: #2666E6;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
}