@import '../../styles/all';

.container {
  min-height: 560px;
  padding: 0 16px;
  .title {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
  }
  .subtitle {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
  }
  .inputWrapper {
    margin-top: 30px;
    .label {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      text-transform: uppercase;
    }
    .input {
      margin-top: 6px;
      width: 100%;
      height: 40px;
    }
    .errorLabel {
      color: #FF0000;
      font-size: 12px;
    }    
  }
}
.tabLayout {
  display: flex;
  align-items: flex-end;
  color: black;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
  @media (max-width: $screen-sm) {
    font-size: 14px;
  }

  .tabItem {
    width: 200px;
    text-align: center;
    padding-bottom: 12px;
    border-bottom: 1.5px solid #C4C4C4;
    opacity: 0.7;
    cursor: pointer;
    @media (max-width: $screen-sm) {
      width: auto;
    }
  }

  .tabItemSelected {
    width: 200px;
    text-align: center;
    padding-bottom: 12px;
    border-bottom: 3px solid #196FE4;
    opacity: 1;
    cursor: pointer;
    @media (max-width: $screen-sm) {
      width: auto;
    }
  }
}